@import "./../../assets/variables.scss";

.checkbox {
    width: fit-content;
    max-width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 1.2rem;

    &__checker {
        flex-shrink: 0;
        width: 2rem;
        height: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        border: 1px solid $gray-7;
        border-radius: 0.5rem;
        transition: background-color 0.5s, border-color 0.5s;

        &.active {
            background-color: $blue;
            border-color: $blue;

            &~.checkbox__label {
                color: $black-2;
            }

            .checkbox__ico {
                opacity: 1;
            }
        }
    }

    &__ico {
        display: block;
        width: 1.2rem;
        height: 0.9rem;
        transition: opacity 0.5s;
    }

    &__label {
        color: $black-2;
        font-size: 1.6rem;
        line-height: 2rem;
        font-weight: 500;
    }

    @media screen and (hover) {
        cursor: pointer;

        .checkbox__label {
            transition: color 0.5s;
        }

        &:hover {
            .checkbox__label {
                color: $blue;
            }

            .checkbox__checker {
                border-color: $blue;
            }
        }
    }
}