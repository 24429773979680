@import "./fonts/Inter/inter-font.scss";

/*Fonts*/
$inter-family: Inter, sans-serif;
/*END Global*/

/*Colors*/
$white: #ffffff;
$black: #101828;
$black-1: #111827;
$black-2: #374151;
$black-3: #333333;
$black-4: rgba($black, 0.6);
$black-6: #000000;
$black-7: rgba($black-6, 0.05);
$black-8: #1D1D1D;
$blue: #2B6FF3;
$blue-1: #F2F7FF;
$gray: #888888;
$gray-2: rgba($black-2, 0.3);
$gray-3: #344054;
$gray-4: #F2F4F7;
$gray-5: #232323;
$gray-6: #D9D9D9;
$gray-7: #D1D5DB;
$gray-8: #E4E7EC;
$gray-9: #D0D5DD;
$gray-10: #667085;
$gray-11: #999999;
$gray-12: rgba($black-8, 0.6);
$gray-13: #6B7280;
$gray-14: #ECECEC;
$gray-15: rgba($gray-14, 0.3);
$gray-16: #FAFAFA;
$gray-17: #92969E;
$gray-18: #909090;
$gray-19: #F9FAFB;
$gray-20: #F8F8F8;
$gray-21: #323232;
$gray-22: #DBDBDB;
$gray-23: #F9F9F9;
$gray-24: #EDF0F5;
$gray-25: #E5EBF4;
$orange: #F4BF00;
$orange-1: #FFFDF0;
$green: #12B76A;
$red: #F04438;
$red-1: #FEF3F2;
/*END Colors*/