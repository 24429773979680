@import './../../assets/variables.scss';

.map {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.stanchionChooser {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 1.2rem;
    padding: 1.2rem;

    @media screen and (max-width: 850px) {
        padding: 0;
    }

    &__header {
        width: 100%;
        display: flex;
        column-gap: 0.8rem;
    }

    &__openButton {
        display: none;
        background-color: $white;
        border: none;
        filter: drop-shadow(0 0.4rem 1.4rem rgba($gray-21, 0.25));
        width: 4rem;
        justify-content: center;
        align-items: center;
        border-radius: 0.8rem;
        flex-shrink: 0;

        @media screen and (hover) {
            cursor: pointer;

            .stanchionChooser__openIco {
                transition: stroke 0.5s;
            }

            &:hover {
                .stanchionChooser__openIco {
                    stroke: $blue;
                }
            }
        }

        @media screen and (max-width: 850px) {
            display: flex;
        }
    }

    &__openIco {
        display: block;
        flex-shrink: 0;
        width: 100%;
        height: 100%;
        stroke: $gray-13;
        fill: none;
        transform: rotateZ(180deg);
    }

    &__inputWrapper {
        width: 0;
        flex-grow: 1;
        display: flex;
        position: relative;
        z-index: 1;
    }

    &__search {
        display: block;
        width: 1.8rem;
        height: 1.8rem;
        position: absolute;
        z-index: 1;
        top: 1.3rem;
        left: 0.8rem;
        pointer-events: none;
    }

    &__input {
        width: 100%;
        min-height: 4.4rem;
        border: 1px solid $gray-20;
        border-radius: 0.8rem;
        background-color: $gray-20;
        padding: 0.5rem 1.2rem 0.5rem 3.6rem;
        color: $black-1;
        font-size: 1.6rem;
        line-height: 2rem;
        font-weight: 400;
    
        &::placeholder {
            color: $gray-11;
        }

        @media screen and (max-width: 850px) {
            background-color: $white;
            filter: drop-shadow(0 0.4rem 1.4rem rgba($gray-21, 0.25));
        }
    }

    &__sortButtonsWrapper {
        width: 100%;
        display: flex;
        column-gap: 0.8rem;
        position: relative;
        z-index: 2;

        @media screen and (max-width: 850px) {
            padding: 0.8rem;
            background-color: $white;
            border-radius: 0.8rem;
            filter: drop-shadow(0 0.4rem 1.4rem rgba($gray-21, 0.25));
        }
    }

    &__sortButton {
        min-height: 4.2rem;
        font-size: 1.6rem;
        width: 0;
        flex-grow: 1;

        @media screen and (max-width: 850px) {
            background-color: transparent;
            color: $blue;

            @media screen and (hover) {
                transition: color 0.5s, background-color 0.5s;
                cursor: pointer;
    
                &:hover {
                    color: $white;
                    background-color: $blue;
                }
            }
        }
    }

    &__filterButton {
        min-height: 4.2rem;
        font-size: 1.6rem;
        width: 0;
        flex-grow: 1;
        display: none;

        @media screen and (max-width: 850px) {
            display: block;
        }
    }
}

.stanchionInfo {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 1.6rem;

    &__chosen {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 0.8rem;
    }

    &__title {
        color: $black-8;
        font-size: 1.8rem;
        line-height: 2.4rem;
        font-weight: 500;
    }

    &__subData {
        width: 100%;
        color: $gray;
        font-size: 1.6rem;
        line-height: 1.9rem;
        font-weight: 400;

        &_blue {
            color: $blue;
        }
    }

    &__fuelTypes {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        column-gap: 1rem;
        row-gap: 1rem;
    }

    &__fuelType {
        display: flex;
        align-items: center;
        column-gap: 1rem;
        max-width: 100%;
        width: fit-content;
        padding: 0.7rem 1.6rem;
        background-color: $white;
        border: 1px solid $gray-7;
        border-radius: 0.6rem;
        cursor: pointer;
        transition: border-color 0.5s, background-color 0.5s;

        @media screen and (max-width: 850px) {
            padding: 0.4rem 0.6rem;
        }

        &.active, &:hover {
            background-color: rgba($color: $blue, $alpha: 0.05);
            border-color: $blue;
        }
    }

    &__fuelColor {
        flex-shrink: 0;
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 50%;
    }

    &__fuelName {
        color: $black-2;
        font-size: 1.4rem;
        line-height: 2rem;
        font-weight: 500;
    }

    &__options {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 1.2rem;
    }

    &__result {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 0.8rem;
    }

    &__button {
        width: 100%;
        min-height: 3.6rem;
        font-size: 1.4rem;

        &_red {
            border-color: $red-1;
            background-color: $red-1;
            color: $red;

            @media screen and (hover) {
                transition: color 0.5s, background-color 0.5s, border-color 0.5s;
                cursor: pointer;
    
                &:hover {
                    color: $white;
                    border-color: $red;
                    background-color: $red;
                }
            }
        }

        &_disable {
            pointer-events: none;
            opacity: 0.3;
        }
    }
}

.showMenuButton {
    width: 100%;
    min-height: 4.2rem;
    background-color: $blue-1;
    border: none;
    border-radius: 0.8rem;
    padding: 0.5rem 1.6rem;
    display: flex;
    justify-content: space-between;
    column-gap: 1rem;
    align-items: center;
    position: relative;
    z-index: 1;

    @media screen and (max-width: 850px) {
        min-width: 15.6rem;
    }

    &__arrows {
        display: block;
        width: 2rem;
        height: 2rem;
        flex-shrink: 0;
        fill: $blue;
        transition: transform 0.5s;

        &.up {
            transform: rotateZ(180deg);
        }
    }

    &__text {
        color: $blue;
        font-size: 1.4rem;
        line-height: 2rem;
        font-weight: 500;
    }

    @media screen and (hover) {
        transition: background-color 0.5s;
        cursor: pointer;

        .showMenuButton__arrows {
            transition: fill 0.5s;
        }

        .showMenuButton__text {
            transition: color 0.5s;
        }

        &:hover {
            background-color: $blue;

            .showMenuButton__arrows {
                fill: $white;
            }

            .showMenuButton__text {
                color: $white;
            }
        }
    }
}

.list {
    width: 100%;
    flex-grow: 1;
    overflow-y: auto;

    &__container {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 0.8rem;
    }

    &__label {
        color: $gray;
        font-size: 1.3rem;
        line-height: 2rem;
        font-weight: 400;
        letter-spacing: 0.03em;
        text-transform: uppercase;
    }

    .listItem {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 0.6rem;
        border: 1px solid $gray-7;
        border-radius: 0.8rem;
        background-color: transparent;
        padding: 0.8rem;

        @media screen and (hover) {
            transition: background-color 0.5s, border-color 0.5s;
            cursor: pointer;

            p, span {
                transition: color 0.5s;
            }

            &:hover {
                background-color: $blue;
                border-color: $blue;

                .listItem__fuel,
                .listItem__address {
                    color: $white;
                }

            }
        }

        &__name {
            width: fit-content;
            max-width: 100%;
            display: flex;
            align-items: center;
            column-gap: 0.6rem;
            background-color: $blue-1;
            border-radius: 1.6rem;
            min-height: 3.1rem;
            padding: 0.5rem 1.2rem;
            color: $blue;
            font-size: 1.6rem;
            line-height: 2rem;
            font-weight: 600;

            &::before {
                content: "";
                display: block;
                width: 0.6rem;
                height: 0.6rem;
                border-radius: 50%;
                background-color: $blue;
            }
        }

        &__address {
            width: 100%;
            color: $black-2;
            font-size: 1.8rem;
            line-height: 2rem;
            font-weight: 400;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &__fuel {
            color: $gray-11;
            font-size: 1.6rem;
            line-height: 2rem;
            font-weight: 400;
        }
    }

    .listStation {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 4.2rem;

        &__location {
            width: 100%;
            display: flex;
            flex-direction: column;
            row-gap: 1.2rem;
        }

        &__address {
            color: $black-8;
            font-size: 2rem;
            line-height: 2.4rem;
            font-weight: 400;
        }

        &__coords {
            color: $gray;
            font-size: 1.8rem;
            line-height: 2.2rem;
            font-weight: 400;
        }

        &__fuelContainer {
            width: 100%;
            display: flex;
            flex-direction: column;
            row-gap: 1.6rem;
        }

        &__fuelTitle {
            color: $black-8;
            font-size: 1.8rem;
            line-height: 2.4rem;
            font-weight: 500;
        }

        &__fuelLine {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            column-gap: 1rem;
        }

        &__fuelTag {
            width: fit-content;
            border-radius: 1.6rem;
            padding: 0.6rem 1.6rem;
            display: flex;
            align-items: center;
            column-gap: 0.6rem;
            color: $black-1;
            font-size: 1.6rem;
            line-height: 2rem;
            font-weight: 400;
            text-transform: uppercase;
        }

        &__fuelMarker {
            flex-shrink: 0;
            width: 0.6rem;
            height: 0.6rem;
            border-radius: 50%;
        }

        &__fuelPrice {
            color: $black-2;
            font-size: 1.6rem;
            line-height: 2rem;
            font-weight: 500;
            text-align: right;
        }

        &__services {
            width: 100%;
            display: flex;
            flex-direction: column;
            row-gap: 1.6rem;
        }

        &__servicesContainer {
            width: 100%;
            display: flex;
            flex-direction: column;
            row-gap: 1.2rem;
        }

        &__service {
            color: $black-2;
            font-size: 1.6rem;
            line-height: 2rem;
            font-weight: 500;
        }

        &__button {
            width: 100%;
            min-height: 5rem;
        }
    }
}