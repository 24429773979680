@import './../assets/variables.scss';

.officeLayout {
    padding: 4rem 0 7.1rem;
    display: flex;
    column-gap: 18.2rem;
    min-height: 100dvh;

    @media screen and (max-width: 1500px) {
        column-gap: 2rem;
    }

    @media screen and (max-width: 850px) {
        padding: 4rem 0 4rem 6.5rem;
        position: relative;
    }

    &_map {
        @media screen and (max-width: 850px) {
            padding: 2rem 0;
        }
    }

    &__content {
        width: 0;
        min-width: 0;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    &__drawerWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        row-gap: 1.2rem;
    }

    &__toMainButton {
        width: 100%;
        min-height: 4.2rem;
        font-size: 1.6rem;
        filter: drop-shadow(0 0.4rem 1.4rem rgba($gray-21, 0.25));
        position: relative;
        z-index: 1;

        @media screen and (max-width: 850px) {
            display: none;
        }
    }

    &__drawer {
        width: 29.6rem;
        border: 1px solid $black-7;
        border-radius: 0.8rem;
        background-color: $white;
        padding: 1.2rem;
        position: relative;
        z-index: 1;

        @media screen and (max-width: 1200px) {
            width: 25rem;
        }

        @media screen and (max-width: 850px) {
            width: 5.6rem;
            overflow: hidden;
            transition: all 0.5s;
            position: absolute;
            left: 0;
            top: 4rem;
            bottom: 1.8rem;
            display: flex;
            padding: 1.2rem 0.5rem 1.2rem 1.2rem;
            background-color: $white;

            svg + span {
                opacity: 0;
                transition: opacity 0.5;
            }
        }

        &_input {
            width: 29.6rem;
            filter: drop-shadow(0 0.4rem 1.4rem rgba($gray-21, 0.25));
            position: relative;
            z-index: 1;
            top: 0;
            padding: 0;

            @media screen and (max-width: 850px) {
                bottom: auto;
                position: fixed;
                top: 2rem;
                left: 2rem;
                right: 2rem;
                width: calc(100% - 4rem);
                background-color: transparent;
                overflow: visible;
                border: none;
            }
        }

        &_info {
            width: 29.6rem;
            filter: drop-shadow(0 0.4rem 1.4rem rgba($gray-21, 0.25));
            position: relative;
            z-index: 2;
            top: 0;

            @media screen and (max-width: 850px) {
                top: 0;
                width: 100%;
                border: none;
                filter: none;
            }
        }

        &_open {
            @media screen and (max-width: 850px) {
                width: 25rem;
                z-index: 2;

                .officeLayout__openButton {
                    transform: rotateZ(180deg);
                    transition: transform 0.5s;
                    will-change: transform;
                }

                .officeLayout__opener {
                    justify-content: flex-end;
                }

                .carts,
                .user {
                    opacity: 1;
                }

                svg + span {
                    opacity: 1;
                }
            }
        }
    }

    &__drawerScroll {
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 0.5rem;
    }

    &__openButton {
        display: block;
        background-color: transparent;
        border: none;
        width: 4rem;
        height: 4rem;
        flex-shrink: 0;
        margin: 0 -0.7rem;

        @media screen and (max-width: 850px) {
            transform: rotateZ(0);
        }

        @media screen and (hover) {
            cursor: pointer;

            .officeLayout__openIco {
                transition: stroke 0.5s;
            }

            &:hover {
                .officeLayout__openIco {
                    stroke: $blue;
                }
            }
        }
    }

    &__openIco {
        display: block;
        flex-shrink: 0;
        width: 100%;
        height: 100%;
        stroke: $gray-13;
        fill: none;
    }

    &__openerWrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-right: 0.5rem;
    }

    &__opener {
        width: 100%;
        padding: 0.5rem 0;
        display: none;
        justify-content: flex-start;

        @media screen and (max-width: 850px) {
            display: flex;
            transition: all 0.5s;
        }
    }

    &__navigation {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 0.8rem;
    }
}

.carts {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 0.8rem;
    margin: 3.2rem 0 13.7rem;

    @media screen and (max-width: 850px) {
        min-width: 20.4rem;
        opacity: 0;
        transition: opacity 0.5s;
        margin: 2rem 0 4rem;
    }

    &__balance {
        width: 100%;
        padding: 0.8rem;
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        column-gap: 1rem;
    }

    &__text {
        color: $black-1;
        font-size: 1.4rem;
        line-height: 2rem;
        font-weight: 500;

        &_right {
            text-align: right;
        }
    }

    &__dots {
        width: 100%;
        min-height: 3.6rem;
        border: 1px solid $black-7;
        border-radius: 0.8rem;
        padding: 0.5rem 4rem;
        display: flex !important;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        column-gap: 1px;
        row-gap: 1px;
        margin-top: 0.8rem;

        button {
            font-size: 0;
            line-height: 0;
            display: block;
            width: 1.1rem;
            height: 1.1rem;
            padding: 0.2rem;
            color: transparent;
            border: 0;
            outline: none;
            background: $gray-6;
            background-clip: content-box;

            @media screen and (hover) {
                cursor: pointer;
                transition: background-color 0.5s;

                &:hover {
                    background: $blue-1;
                    background-clip: content-box;
                }
            }
        }

        li {
            list-style-type: none;
        }
    }
}

.carts_list {
    display: flex;
    flex-direction: column;
    border-radius: 0.8rem;
    background: #f2f6ff;
    &__title {
        padding: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1.4rem;
        font-weight: 500;
        color: #111827;
        cursor: pointer;
        line-height: 2rem;
        svg {
            width: 32px;
            height: 32px;
            transition: 0.3s ease;
        }
        &_open {
            svg {
                transform: rotate(180deg);
            }
        }
    }
    &__list {
        display: grid;
        grid-template-rows: 0fr;
        transition: 0.3s ease;
        &_open {
            grid-template-rows: 1fr;
        }
    }
    &__list_wrapper {
        overflow: hidden;
        display: flex;
        transition: 0.3s ease;
        flex-direction: column;
        gap: 8px;
        &::-webkit-scrollbar {
            width: 0;
        }
        &_open {
            overflow: auto;
        }
    }
}

.cart {
    width: 100%;
    padding: 1.2rem;
    border: 1px solid $black-7;
    border-radius: 0.8rem;
    display: flex !important;
    flex-direction: column;
    row-gap: 1.2rem;

    &_click {
        @media screen and (hover) {
            cursor: pointer;
            transition: opacity 0.5s;

            &:hover {
                opacity: 0.5;
            }
        }
    }

    &__number {
        width: 100%;
        padding: 0.8rem 0.5rem;
        background-color: $blue-1;
        border-radius: 0.8rem;
        display: flex;
        justify-content: center;
        color: $blue;
        font-size: 1.4rem;
        line-height: 2rem;
        font-weight: 600;
    }

    &__rest {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 0.8rem;
    }

    &__label {
        width: 100%;
        color: $blue;
        font-size: 1.4rem;
        line-height: 2rem;
        font-weight: 400;
    }

    &__count {
        width: 100%;
        color: $blue;
        font-size: 3.2rem;
        line-height: 3.8rem;
        font-weight: 600;
    }

    &__button {
        min-height: 3.6rem;
        width: 100%;
        padding: 0.5rem;
        border: 2px solid $blue;
        background-color: transparent;
        border-radius: 0.8rem;
        color: $blue;
        font-size: 1.4rem;
        line-height: 2rem;
        font-weight: 500;
        text-align: center;

        @media screen and (hover) {
            cursor: pointer;
            transition: background-color 0.5s;

            &:hover {
                background-color: $blue-1;
            }
        }
    }
}

.user {
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 1.4rem;
    padding: 0.8rem 1rem;
    background-color: $blue-1;
    border-radius: 0.8rem;

    @media screen and (max-width: 850px) {
        min-width: 20.4rem;
        opacity: 0;
        transition: opacity 0.5s;
    }

    &__logoWrap {
        display: block;
        flex-shrink: 0;
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        overflow: hidden;

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__info {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    &__label {
        color: $black;
        font-size: 1.4rem;
        line-height: 2rem;
        font-weight: 500;
    }

    &__name {
        color: rgba($color: $black, $alpha: 0.5);
        font-size: 1.4rem;
        line-height: 2rem;
        font-weight: 500;
    }

    &__button {
        display: flex;
        flex-shrink: 0;
        width: 2rem;
        height: 2rem;
        border: none;
        background-color: transparent;

        @media screen and (hover) {
            cursor: pointer;
            transition: filter 0.5s;

            &:hover {
                filter: grayscale(1);
            }
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
}

.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 4.2rem;

    &__buttons {
        display: flex;
        gap: 16px;
    }

    &__container {
        display: flex;
        flex-direction: column;
        row-gap: 2.4rem;
    }

    &__submit {
        width: 100%;
        min-height: 5rem;
    }

    &__textWrapper {
        width: 100%;
        display: flex;
        align-items: center;
        column-gap: 1.2rem;
        padding: 0.5rem 0.8rem;
        min-height: 5rem;
    }

    &__text {
        color: $gray-11;
        font-size: 1.8rem;
        line-height: 2.1rem;
        font-weight: 600;

        &_green {
            color: $green;
        }
    }

    &__icoInfo {
        display: block;
        flex-shrink: 0;
        width: 2.4rem;
        height: 2.4rem;
        fill: $blue;
    }

    &__cards {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
    }

    &__date {
        color: rgba($color: $black-2, $alpha: 0.6);
        font-size: 2rem;
        line-height: 2rem;
        font-weight: 400;
    }

    &__finishInfo {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 2.4rem;
    }

    &__cardBG {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 0.8rem;
        background-color: $blue-1;
        border-radius: 11rem;
        width: fit-content;
        padding: 0.8rem 2rem;

        &::before {
            content: '';
            display: block;
            flex-shrink: 0;
            background-color: $blue;
            width: 0.8rem;
            height: 0.8rem;
            border-radius: 50%;
        }
    }

    &__cardNumber {
        color: $blue;
        font-size: 1.8rem;
        line-height: 2rem;
        font-weight: 600;
    }

    &__number {
        color: $blue;
        font-size: 1.6rem;
        line-height: 2rem;
        font-weight: 600;
    }

    &__userFIO {
        color: $black-1;
        font-size: 1.8rem;
        line-height: 2.1rem;
        font-weight: 500;
        padding: 1.4rem 0;

        &_green {
            color: $green;
            font-weight: 700;
        }
    }
}

.publicLayout {
    width: 100%;
    display: flex;
    flex-grow: 1;

    @media screen and (max-width: 1200px) {
        flex-direction: column-reverse;
    }

    &__main {
        width: 38%;
        min-width: 48rem;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media screen and (max-width: 1200px) {
            width: 100%;
            min-width: 0;
            flex-grow: 1;
            padding-top: 8rem;
            padding-bottom: 8rem;
        }

        @media screen and (max-width: 650px) {
            width: 100%;
            min-width: 0;
            flex-grow: 1;
            padding: 4rem 2rem;
        }

        &::before,
        &::after {
            content: '';
            display: block;
            flex-grow: 1;

            @media screen and (max-width: 1200px) {
                display: none;
            }
        }
    }

    &__bg {
        width: 0;
        min-width: 0;
        flex-grow: 1;
        background:
            url(./../assets/images/bg_pub_layout.png) no-repeat,
            radial-gradient(circle, rgba(43, 111, 243, 1) 0%, rgba(25, 64, 141, 1) 100%) no-repeat;
        background-size:
            cover,
            200% 200%;
        background-position:
            100% 50%,
            100% 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 2rem;

        @media screen and (max-width: 1500px) {
            background-position:
                65% 50%,
                100% 100%;
        }

        @media screen and (max-width: 1200px) {
            width: 100%;
            flex-grow: 0;
            background-size:
                auto 190%,
                200% 200%;
            background-position:
                45% 50%,
                100% 100%;
        }
    }

    &__logoWrap {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $white;
        border-radius: 3rem;
        padding: 9.6rem 12.6rem;

        @media screen and (max-width: 1500px) {
            padding: 3rem;
        }

        @media screen and (max-width: 1200px) {
            padding: 2rem;
        }
    }

    &__logo {
        display: block;
        width: 19.2rem;
        flex-shrink: 0;

        @media screen and (max-width: 1200px) {
            width: 13rem;
        }

        @media screen and (max-width: 650px) {
            width: 8rem;
        }
    }

    .formWrapper {
        width: 44rem;
        max-width: 100%;
    }

    .formTitle {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        color: $black-1;
        font-size: 4.2rem;
        line-height: 5.2rem;
        font-weight: 500;
        margin-bottom: 4.2rem;

        &_block {
            flex-direction: column;
            row-gap: 2.4rem;
            align-items: center;
        }
    }

    .formSubsription {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        color: rgba($color: $black-1, $alpha: 0.6);
        font-size: 2.4rem;
        line-height: 2.9rem;
        font-weight: 400;
    }
}

.drivers {
    width: 100%;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    row-gap: 2.4rem;

    &__titleCount {
        color: $blue;
    }

    &__search {
        margin-top: 24px;
    }

    &__pagination {
        display: flex;
        flex-direction: column;
        gap: 1.25rem;
        margin-top: 1.25rem;
    }

    &__addDriver {
        width: 100%;
        min-height: 5rem;
        padding: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 1.2rem;
        border: 1px solid $blue;
        border-radius: 0.8rem;
        background-color: transparent;

        @media screen and (hover) {
            cursor: pointer;
            transition: background-color 0.5s;

            .drivers__addDriverText {
                transition: color 0.5s;
            }

            .drivers__addDriverIco {
                transition: stroke 0.5s;
            }

            &:hover {
                background-color: $blue;

                .drivers__addDriverText {
                    color: $white;
                }

                .drivers__addDriverIco {
                    stroke: $white;
                }
            }
        }
    }

    &__addDriverText {
        color: $blue;
        font-size: 1.8rem;
        line-height: 2.1rem;
        font-weight: 500;
    }

    &__addDriverIco {
        display: block;
        flex-shrink: 0;
        width: 2.4rem;
        height: 2.4rem;
        stroke: $blue;
    }

    &__list {
        width: 100%;
        flex-shrink: 0;
        display: grid;
        // grid-auto-rows: auto;
        row-gap: 1rem;
    }

    &__edit {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 4.2rem;
        height: 0;
        flex-grow: 1;
    }

    &__partWrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 2.4rem;
        overflow: auto;
    }

    &__partTitle {
        color: $black-1;
        font-size: 2.4rem;
        line-height: 3rem;
        font-weight: 500;

        &_blue {
            color: $blue;
        }
    }

    &__cardsListScroll {
        width: 100%;
        overflow: auto;
    }

    &__cardsListWrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 0.8rem;
    }

    &__buttonWrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
    }

    &__button {
        width: 100%;
        min-height: 5rem;
        font-size: 1.8rem;
        line-height: 2.1rem;

        &_red {
            border-color: $red-1;
            background-color: $red-1;
            color: $red;

            @media screen and (hover) {
                transition:
                    color 0.5s,
                    background-color 0.5s,
                    border-color 0.5s;
                cursor: pointer;

                &:hover {
                    color: $white;
                    border-color: $red;
                    background-color: $red;
                }
            }
        }
    }

    &__fieldListWrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 2.4rem;
    }

    &__buttonsBlock {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 0.8rem;
    }

    &__fieldTypeTag {
        width: fit-content;
        max-width: 100%;
        background-color: rgba($color: $orange, $alpha: 0.15);
        border-radius: 10rem;
        padding: 0.6rem 2.4rem;
        display: flex;
        align-items: center;
        column-gap: 0.6rem;
        color: $black-1;
        font-size: 2.4rem;
        line-height: 2.9rem;
        font-weight: 400;

        &::before {
            content: '';
            display: block;
            width: 1rem;
            height: 1rem;
            background-color: $orange;
            border-radius: 50%;
        }
    }
}
