@import "./../../assets/variables.scss";

.field {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 1.2rem;

    &__label {
        color: $gray-12;
        font-size: 1.8rem;
        line-height: 2rem;
        font-weight: 500;
    }

    &__wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 0.5rem;
    }

    &__error {
        color: $red;
        font-size: 1.3rem;
        line-height: 2rem;
        font-weight: 400;

        &_helper {
            color: $gray;
        }
    }
}