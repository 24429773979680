@import "./../../assets/variables.scss";

.calendar {
    display: flex !important;
    width: fit-content;
    max-width: calc(100dvw - 6rem);

    @media screen and (max-width: 440px) {
        max-width: calc(100dvw - 4rem);
        & > div {
            font-size: 10px;
        }
    }

    @media screen and (max-width: 370px) {
        & > div {
            font-size: 9px;
        }
    }

    &__wrapper {
        display: flex;
        position: relative;
        z-index: 1;
    }

    &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 1rem;
        padding: 0.8rem 1.8rem;
        background-color: transparent;
        border: 1px solid $blue;
        border-radius: 0.8rem;

        @media screen and (hover) {
            cursor: pointer;
            transition: background-color 0.5s;

            &:hover {
                background-color: $blue-1;
            }
        }
    }

    &__ico {
        display: block;
        flex-shrink: 0;
        width: 2rem;
        height: 2rem;
        stroke: $blue;
    }

    &__label {
        display: flex;
        color: $blue;
        font-size: 1.6rem;
        line-height: 2.2rem;
        font-weight: 500;
    }

    &__form {
        display: none;
        flex-direction: column;
        opacity: 0;
        transition: opacity 0.5s;
        background-color: $white;
        position: absolute;
        right: 0;
        top: calc(100% + 0.3rem);
        filter: drop-shadow(0 2rem 1.4rem rgba($black, 0.1));

        &_vis {
            display: flex;
        }

        &_show {
            opacity: 1;
        }
    }

    &__footer {
        width: 100%;
        border-top: 1px solid $gray-8;
        padding: 1.6rem 2.4rem;
        display: flex;
        justify-content: flex-end;
        column-gap: 1.2rem;

        @media screen and (max-width: 370px) {
            padding: 1.6rem 1rem;
        }
    }
}
