@import './../../assets/variables.scss';

.portal {
    position: fixed;
    z-index: 10;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    background-color: rgba($color: $black-6, $alpha: 0.25);
    opacity: 0;
    transition: opacity 0.5s;

    &_show {
        opacity: 1;
    }

    &_map {
        @media screen and (max-width: 1200px) {
            padding: 1rem 2rem;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            height: auto;
            max-height: 100%;

            &::before, &::after {
                content: "";
                display: block;
                flex-grow: 1;
            }

            @media screen and (max-width: 850px) {
                align-items: flex-end;
                background-color: transparent;
                top: 13.4rem;
                width: 100%;
                max-width: 100%;
                pointer-events: none;
            }
        }
    }

    &_mapLeft {
        @media screen and (max-width: 850px) {
            align-items: flex-start;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 63.4rem;
        height: 100%;
        background-color: $white;
        padding: 2.4rem 4.2rem;
        filter: drop-shadow(-1.2rem 0 3.2rem rgba($black-6, 0.25));

        @media screen and (max-width: 850px) {
            padding: 1rem;
        }

        &_map {
            @media screen and (max-width: 1200px) {
                height: auto;
                max-height: 100%;
            }

            @media screen and (max-width: 850px) {
                padding: 2rem;
                width: 32rem;
                max-width: 100%;
                pointer-events: all;
            }
        }
    }

    &__scroll {
        width: calc(100% + 2rem);
        height: 0;
        flex-grow: 1;
        padding-right: 2rem;
        margin-right: -2rem;
        overflow: auto;
        display: flex;
        flex-direction: column;

        &_map {
            @media screen and (max-width: 1200px) {
                height: auto;
            }
        }
    }

    &__title {
        color: $black-1;
        font-size: 2.4rem;
        line-height: 2.4rem;
        font-weight: 500;
        margin-bottom: 4.2rem;
    }

    &__header {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 2rem;
    }

    &__close {
        display: block;
        width: 2.4rem;
        height: 2.4rem;
        border: none;
        background-color: transparent;

        @media screen and (hover) {
            cursor: pointer;

            .portal__ico {
                transition: stroke 0.5s;
            }

            &:hover {
                .portal__ico {
                    stroke: $blue;
                }
            }
        }
    }

    &__ico {
        display: block;
        width: 100%;
        height: 100%;
        stroke: $black-1;
    }
}