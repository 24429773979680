@font-face {
    font-family: "Inter", sans-serif;
    src: url("./static/Inter-Thin.ttf");
    font-optical-sizing: auto;
    font-weight: 100;
    font-style: normal;
    font-variation-settings: "slnt" 0;
}

@font-face {
    font-family: "Inter", sans-serif;
    src: url("./static/Inter-ExtraLight.ttf");
    font-optical-sizing: auto;
    font-weight: 200;
    font-style: normal;
    font-variation-settings: "slnt" 0;
}

@font-face {
    font-family: "Inter", sans-serif;
    src: url("./static/Inter-Light.ttf");
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    font-variation-settings: "slnt" 0;
}

@font-face {
    font-family: "Inter", sans-serif;
    src: url("./static/Inter-Regular.ttf");
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings: "slnt" 0;
}

@font-face {
    font-family: "Inter", sans-serif;
    src: url("./static/Inter-Medium.ttf");
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    font-variation-settings: "slnt" 0;
}

@font-face {
    font-family: "Inter", sans-serif;
    src: url("./static/Inter-SemiBold.ttf");
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-variation-settings: "slnt" 0;
}

@font-face {
    font-family: "Inter", sans-serif;
    src: url("./static/Inter-Bold.ttf");
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
    font-variation-settings: "slnt" 0;
}

@font-face {
    font-family: "Inter", sans-serif;
    src: url("./static/Inter-ExtraBold.ttf");
    font-optical-sizing: auto;
    font-weight: 800;
    font-style: normal;
    font-variation-settings: "slnt" 0;
}

@font-face {
    font-family: "Inter", sans-serif;
    src: url("./static/Inter-Black.ttf");
    font-optical-sizing: auto;
    font-weight: 900;
    font-style: normal;
    font-variation-settings: "slnt" 0;
}