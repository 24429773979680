@import "./../../assets/variables.scss";

.input {
    min-height: 5rem;
    width: 100%;
    border: 1px solid $gray-7;
    border-radius: 0.8rem;
    padding: 0.5rem 0.8rem;
    color: $black-1;
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 500;

    &::placeholder {
        color: $gray-11;
    }

    &_error {
        border-color: $red;
    }
}