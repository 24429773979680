@import './../../../assets/variables.scss';

.chat {
    &__titleWrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 2.4rem;
        row-gap: 1rem;
        flex-wrap: wrap;
        margin-bottom: 4.2rem;
    }

    &__title {
        color: $black-1;
        font-size: 2.4rem;
        line-height: 2.4rem;
        font-weight: 500;
        text-align: center;
    }

    &__ico {
        flex-shrink: 0;
        display: block;
        width: 5rem;
        height: 5rem;
    }

    &__dateTime {
        width: 100%;
        margin-bottom: 1.4rem;
        margin-top: 1.6rem;
        color: $black-1;
        font-size: 1.6rem;
        line-height: 1.6rem;
        font-weight: 400;
        letter-spacing: 0.04em;
        text-align: center;
    }

    &__wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        height: 0;
        flex-grow: 1;
        row-gap: 4rem;

        &_manager {
            @media screen and (max-width: 650px) {
                height: auto;
                min-height: 20rem;
            }
        }
    }

    &__content {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        height: 0;
        flex-grow: 1;
        row-gap: 1rem;
        overflow: auto;

        &_manager {
            @media screen and (max-width: 650px) {
                height: auto;
                min-height: 20rem;
            }
        }
    }

    &__item {
        display: flex;
        width: 100%;

        &_out {
            justify-content: flex-end;
        }
    }

    &__part {
        width: calc(50% - 2.7rem);
        background-color: $gray-15;
        border-radius: 0.8rem;
        padding: 0.8rem;
        display: flex;
        flex-direction: column;
        row-gap: 1rem;

        @media screen and (max-width: 1200px) {
            width: calc(50% - 1rem);
        }

        @media screen and (max-width: 850px) {
            width: 75%;
        }

        &_out {
            align-items: flex-end;
            background-color: $blue;

            * {
                color: $white !important;
            }
        }
    }

    &__message {
        color: $black-3;
        font-size: 1.4rem;
        line-height: 1.8rem;
        font-weight: 400;
    }

    &__date {
        color: $gray-5;
        font-size: 1.4rem;
        line-height: 1.8rem;
        font-weight: 500;
    }

    &__footer {
        width: 100%;
        display: flex;
        column-gap: 0.6rem;
    }

    &__input {
        min-height: 4.8rem;
        width: 0;
        min-width: 0;
        flex-grow: 1;
        border: 1px solid $gray-16;
        border-radius: 0.8rem;
        background-color: $gray-16;
        padding: 0.5rem 1.6rem;
        color: $black-3;
        font-size: 1.2rem;
        line-height: 1.6rem;
        font-weight: 400;
        letter-spacing: 0.02em;

        &::placeholder {
            color: $gray-17;
            font-size: 1.2rem;
            line-height: 1.6rem;
            font-weight: 400;
            letter-spacing: 0.02em;
        }
    }

    &__buttonIco {
        display: block;
        flex-shrink: 0;
        width: 2rem;
        height: 2rem;
        stroke: $white;
    }

    &__button {
        width: 4.8rem;
        height: 4.8rem;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.8rem;
        background-color: $blue;
        border: 1px solid $blue;

        @media screen and (hover) {
            cursor: pointer;
            transition: background-color 0.5s;

            .chat__buttonIco {
                transition: stroke 0.5s;
            }

            &:hover {
                background: transparent;

                .chat__buttonIco {
                    stroke: $blue;
                }
            }
        }

        &_disabled {
            pointer-events: none;
            opacity: 0.5;
        }
    }
}
