@import "./../../assets/variables.scss";

.h1 {
    color: $black;
    font-size: 3rem;
    line-height: 3.8rem;
    font-weight: 500;
}

.h2 {
    color: $black-1;
    font-size: 2.4rem;
    line-height: 2.9rem;
    font-weight: 500;
    letter-spacing: 0.03em;
    text-transform: uppercase;
}

.p {
    color: $gray-5;
    font-size: 1.4rem;
    line-height: 1.6rem;
    font-weight: 500;
}