@import './../../../../assets/variables.scss';

.pagination {
    width: 0;
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 1.6rem;
    row-gap: 1.2rem;

    &__paginationWrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        column-gap: 1.4rem;
        align-items: center;
    }

    &__paginationArrow {
        display: block;
        flex-shrink: 0;
        width: 2.4rem;
        height: 2.5rem;

        path {
            stroke: $gray-13;
        }

        &_next {
            transform: scaleX(-1);
        }

        @media screen and (hover) {
            cursor: pointer;

            & path {
                transition: stroke 0.5s;
            }

            &:hover {
                path {
                    stroke: $blue;
                }
            }
        }
    }

    &__page {
        padding: 0.6rem 0.8rem;
        background-color: transparent;
        border-radius: 0.8rem;
        transition:
            background-color 0.5s,
            color 0.5s;
        color: $black;
        font-size: 1.4rem;
        line-height: 1.7rem;
        font-weight: 400;
        letter-spacing: 0.03em;

        @media screen and (hover) {
            cursor: pointer;

            &:hover {
                background-color: $blue-1;
            }
        }

        &.active {
            background-color: $blue;
            color: $white;
        }
    }
}
