@import './../../assets/variables.scss';

.menuLink {
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 1.2rem;
    justify-content: space-between;
    padding: 0.8rem 1.2rem;
    border-radius: 0.8rem;
    background-color: transparent;

    @media screen and (max-width: 1200px) {
        padding: 0.5rem 0;
    }

    @media screen and (hover) {
        cursor: pointer;
        transition: background-color 0.5s;

        .menuLink__title {
            transition:
                color 0.5s,
                font-weight 0.3s;
        }

        .menuLink__ico {
            transition:
                stroke 0.5s,
                fill 0.5s;
        }

        &:hover {
            background-color: $blue-1;

            .menuLink__title {
                color: $blue;
                font-weight: 500;
            }

            .menuLink__ico {
                fill: $blue;

                &_stroke {
                    stroke: $blue;
                    fill: none;
                }
            }
        }
    }

    &__wrapper {
        flex-grow: 1;
        display: flex;
        align-items: center;
        column-gap: 1.2rem;
    }

    &__title {
        color: $black-1;
        font-size: 1.6rem;
        line-height: 2.4rem;
        font-weight: 400;

        @media screen and (max-width: 1200px) {
            font-size: 1.4rem;
            line-height: 2rem;
        }

        @media screen and (max-width: 850px) {
            white-space: nowrap;
        }
    }

    &__ico {
        display: block;
        width: 2.4rem;
        height: 2.4rem;
        fill: $black-1;
        flex-shrink: 0;

        @media screen and (max-width: 1200px) {
            width: 2rem;
            height: 2rem;
        }

        &_stroke {
            stroke: $black-1;
            fill: none;
        }
    }

    &__count {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        padding: 0 0.9rem;
        border-radius: 0.4rem;
        background-color: $blue;
        color: $white;
        font-size: 1.2rem;
        line-height: 2.4rem;
        font-weight: 600;
    }

    &_active {
        background-color: transparent;
        // pointer-events: none;

        .menuLink__title {
            color: $blue;
        }

        .menuLink__ico {
            fill: $blue;

            &_stroke {
                stroke: $blue;
                fill: none;
            }
        }
    }
}
