@import "./../../assets/variables.scss";

.button {
    min-height: 4rem;
    min-width: 10.9rem;
    padding: 0.5rem;
    border: 1px solid $blue;
    background-color: $blue;
    border-radius: 0.8rem;
    color: $white;
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 500;

    @media screen and (hover) {
        cursor: pointer;
        transition: background-color 0.5s;

        &:hover {
            background-color: rgba($color: $blue, $alpha: 0.7);
        }
    }

    &_outline {
        border: 1px solid $gray-9;
        background-color: transparent;
        color: $gray-3;

        @media screen and (hover) {
            &:hover {
                background-color: $gray-6;
            }
        }
    }

    &__ico {
        display: block;
        flex-shrink: 0;
        width: 2rem;
        height: 2rem;
    }
}