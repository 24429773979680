@import "./../../assets/variables.scss";

.fuelCard {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 1rem;
    border: 1px solid $gray-7;
    border-radius: 0.8rem;
    padding: 1.2rem;
    background-color: transparent;

    @media screen and (hover) {
        cursor: pointer;
        transition: background-color 0.5s;

        &:hover {
            background-color: $gray-4;
        }
    }

    &__data {
        width: 0;
        min-width: 0;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        row-gap: 1.2rem;
    }

    &__numberBG {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 0.8rem;
        background-color: $blue-1;
        border-radius: 11rem;
        width: fit-content;
        padding: 0.8rem 2rem;

        &::before {
            content: "";
            display: block;
            flex-shrink: 0;
            background-color: $blue;
            width: 0.8rem;
            height: 0.8rem;
            border-radius: 50%;
        }
    }

    &__number {
        color: $blue;
        font-size: 1.6rem;
        line-height: 2rem;
        font-weight: 600;
    }

    &__name {
        color: $gray-11;
        font-size: 1.8rem;
        line-height: 2.1rem;
        font-weight: 400;
    }

    &__ico {
        flex-shrink: 0;
        display: block;
        width: 2.8rem;
        height: 2.8rem;
    }
}