@import './../../assets/variables.scss';

.alerts {
    position: fixed;
    z-index: 11;
    pointer-events: none;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: auto;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    align-items: flex-start;
    padding: 1rem;
    overflow: auto;
    // pointer-events: none;

    &__item {
        width: fit-content;
        max-width: 300px;
        width: 100%;
        padding: 1.2rem 2.4rem;
        color: $white;
        font-size: 1.6rem;
        line-height: 2rem;
        font-weight: 500;

        &_success {
            background-color: $green;
        }

        &_error {
            background-color: $red;
            width: auto;
            max-width: 100vw;
        }

        &_info {
            background-color: $blue;
        }
    }
}
