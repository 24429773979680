@import "./../../assets/variables.scss";

.driver {
    width: 100%;
    min-height: 9.2rem;
    display: flex;
    justify-content: space-between;
    column-gap: 1rem;
    align-items: center;
    border: 1px solid $gray-7;
    background-color: transparent;
    border-radius: 0.8rem;
    padding: 1.2rem;
    transition: background-color 0.5s, border-color 0.5s;
    cursor: pointer;

    &.active, &:hover {
        background-color: $blue;
        border-color: $blue;

        .driver__name,
        .driver__balance,
        .driver__label {
            color: $white;
        }

        .driver__arrow {
            filter: brightness(5);
        }
    }

    &__data {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        row-gap: 1.2rem;
    }

    &__name {
        color: $black-2;
        font-size: 1.8rem;
        line-height: 2rem;
        font-weight: 500;
        transition: color 0.5s;

        @media screen and (max-width: 650px) {
            font-size: 1.2rem;
            line-height: 1.4rem;
        }
    }

    &__card {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        column-gap: 1.2rem;
        row-gap: 0.8rem;
    }

    &__label {
        color: $gray-11;
        font-size: 1.8rem;
        line-height: 2.1rem;
        font-weight: 400;
        transition: color 0.5s;
    }

    &__numberBG {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 0.8rem;
        background-color: $blue-1;
        border-radius: 11rem;
        width: fit-content;
        padding: 0.8rem 2rem;

        @media screen and (max-width: 650px) {
            min-width: 0;
        }

        &::before {
            content: "";
            display: block;
            flex-shrink: 0;
            background-color: $blue;
            width: 0.8rem;
            height: 0.8rem;
            border-radius: 50%;
        }
    }

    &__number {
        color: $blue;
        font-size: 1.6rem;
        line-height: 2rem;
        font-weight: 600;
    }

    &__arrow {
        flex-shrink: 0;
        display: block;
        width: 2.8rem;
        height: 2.8rem;
        transition: filter 0.5s;
        will-change: filter;

        @media screen and (max-width: 650px) {
            width: 2rem;
            height: 2rem;
        }
    }
}